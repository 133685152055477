import  { ChartWithOverlay } from "./ChartWithOverlay";

export const DepthTempChart = ({
    title,
    margins,
    xLim,
    yLim,
    traces,
    highlighted,
    xLines,
    setXLines,
    setCursorData,
    tcs,
    updateDepths,
    handleWidthUpdate,
    useCrosshairs,
    usePointOverlay,
    userPalettes
}) => {
    return <ChartWithOverlay 
        margins={margins}
        xLim={xLim}
        yLim={yLim}
        title={title}
        traces={traces}
        xUnit="mKB"
        yUnit="℃"
        tcs={tcs}
        highlighted={highlighted}
        setCursorData={setCursorData}
        xLines={xLines}
        setXLines={setXLines}
        onDoubleClick={updateDepths}
        xFormatter={(d) => `${Math.round(d)} mKB`}
        yFormatter={(t) => `${Math.round(t*10)/10} °C`}
        handleWidthUpdate={handleWidthUpdate}
        useCrosshairs={useCrosshairs || true}
        usePointOverlay={usePointOverlay}
        userPalettes={userPalettes}
    />
}

