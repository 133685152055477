import { depthGen } from "../../utils/depth";
import { colourWheel } from "./Trace/Colours";

/*

  "Name": "EEE10P",
  "Site": "",
  "Timestamp": "2023-02-02T23:41:09Z",
  "Chan": 10,
  "Cals": [
    {
      "Length": 100,
      "RefInd": 1.46,
      "DiffAtt": 0.25,
      "EFL": 1,
      "Offset": 0,
      "Gain": 1
    },
  ],
  "Depth": null,
  "Temp": [
    26.97,
    27.04,
    27.240000000000002,
    27.3,
  ]
  "Loss": [
    0.006,
    0.009000000000000001,
    0.003,
  ],
  "Ratio": [
    -6.607,
    -6.606,
    -6.602,
  ],
  "Zone": [
    {
      "b": 892,
      "e": 998,
      "mx": 178.56,
      "mxl": 995,
      "mn": 169.76,
      "mnl": 900
    },
  ],
  "TC": [
    {
      "Depth": 916,
      "FOTemp": 173.96,
      "TCTemp": 186.8
    },
  ],
  "Start": -240,
  "Step": 1,
  "Span": 2327
}
*/

//Convert data from API to format for chart
const convertLatest = (trace, color) => {
  if (!trace || !trace.Timestamp || !trace.Temp?.length) return;

  return {
    key: trace.Timestamp,
    yData: trace.Temp,
    xData: depthGen({
      start: trace.Start,
      step: trace.Step,
      span: trace.Span,
    }),
    colour: color, // Use the provided color
    pin: false,
    visible: true,
  };
};

export default convertLatest;
