let colourCount = 0;

const defaultColours = [
    "#058728", // Darker Green
    "#DA70D6", // Orchid
    "#1A1A1A", // Dark Charcoal
    "#4A90E2", // Sky Blue
    "#FF9900", // Darker Orange
    "#6BBE45", // Bright Lime Green
    "#A05DBB", // Lavender Purple
    "#808080", // Darker Grey
    "#1ABC9C", // Teal
    "#FF6F61", // Coral Red
];

export const colourWheel = (chartColors) => {
    const colors = chartColors && chartColors.length > 0 ? chartColors : defaultColours;
    const color = colors[colourCount % colors.length];
    colourCount += 1;
    return color;
};
