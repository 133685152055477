import useApiClient from './useApi';

// Used to interact with wellnote data.
const useDBWellApi = () => {
  const apiClient = useApiClient();

  const addNewWellNote = ({ site, well, newNoteObject }) => {
    return apiClient.post(`/dts/site/${site}/${well}/wellnote/add`, newNoteObject);
  };
  
  const editWellNote = ({ site, well, noteID, updatedNoteObject }) => {
    return apiClient.patch(`/dts/site/${site}/${well}/wellnote/update/${noteID}`, updatedNoteObject);
  };

  const deleteWellNote = ({ site, well, noteID }) => {
    return apiClient.delete(`/dts/site/${site}/${well}/wellnote/delete/${noteID}`);
  };

  const addNewDOI = ({ site, well, newDOIObject }) => {
    return apiClient.post(`/dts/site/${site}/${well}/doi/add`, newDOIObject);
  }

  const editDOI = ({ site, well, doiID, updatedDOIObject }) => {
    return apiClient.patch(`/dts/site/${site}/${well}/doi/update/${doiID}`, updatedDOIObject);
  };

  const deleteDOI = ({ site, well, doiID }) => {
    return apiClient.delete(`/dts/site/${site}/${well}/doi/delete/${doiID}`);
  };

  const getSavedViews = ({ site, well }) => {
    return apiClient.get(`/dts/site/${site}/${well}/view`);
  };

  const addNewSavedView = ({ site, well, newViewObject }) => {
    return apiClient.post(`/dts/site/${site}/${well}/view/add`, newViewObject);
  };

  const updateSavedView = ({ site, well, viewID, updatedViewObject }) => {
    return apiClient.patch(`/dts/site/${site}/${well}/view/update/${viewID}`, updatedViewObject);
  };

  const deleteSavedView = ({ site, well, viewID }) => {
    return apiClient.post(`/dts/site/${site}/${well}/view/delete/${viewID}`);
  };

  const getTempAtDepth = ({ site, well, requestObject }) => {
    return apiClient.post(`/dts/site/${site}/${well}/depth/temp`, requestObject);
  };

  const getLossAtDepth = ({ site, well, requestObject }) => {
    return apiClient.post(`/dts/site/${site}/${well}/depth/loss`, requestObject);
  };

  const getUserPalettes = () => {
    return apiClient.get(`/user/palettes`);
  };

  const upsertUserPalette = (paletteObject) => {
    return apiClient.post(`/user/palette/upsert`, paletteObject);
  };
  
    return {  
      addNewWellNote, 
      editWellNote,
      addNewDOI, 
      editDOI,
      deleteWellNote,
      deleteDOI,
      getSavedViews,
      addNewSavedView,
      updateSavedView,
      deleteSavedView,
      getTempAtDepth,
      getLossAtDepth,
      getUserPalettes,
      upsertUserPalette
    };
};

export { useDBWellApi };