import { useParams, Link } from "react-router-dom";
import { SiteAPI } from "../../../api"
import { useContext, useEffect, useState, useMemo, useCallback, useRef, use } from "react";

import { Typography, Collapse, Divider, Checkbox, DatePicker, Button } from "antd";
import { EditOutlined } from "@ant-design/icons"

import WellApi, { WellContext } from "../../../api/well";

import { PdecStatus } from "../../../components/Status/PdecStatus";

import { DepthTempChart } from "../../../components/Charts/DepthTempChart";
import { DepthLossChart } from "../../../components/Charts/DepthLossChart";

import dayjs from "dayjs";

import convertLatest from "../../../components/Charts/convertLatest";
import { colourWheel } from "../../../components/Charts/Trace/Colours";

import { UserContext } from "../../../api/user";

import { adjustToBrowserTime } from "../../../utils/adjustToBrowserTime";
import { LimitsDropdownOverlay } from "../../../components/Charts/Selectors/LimitsDropdownOveraly";
import useApiClient from "../../../api/useApi";

import "./site.css";


const { Title }  = Typography;
const { Panel } = Collapse;
 
const margins = {
  left: 50,
  right: 60,
  top: 20,
  bottom: 30
}

const marginsLoss = {
  left: 55,
  right: 60,
  top: 20,
  bottom: 30
}

const defaultColors = [
  "#058728",
  "#DA70D6",
  "#1A1A1A",
  "#4A90E2",
  "#FF9900",
  "#6BBE45",
  "#A05DBB",
  "#808080",
  "#1ABC9C",
  "#FF6F61",
];

const WellSummary = ({ site, well, latest, allWellsLatest, color }) => {
  const wellApi = WellApi(site, well);
  const { queryTimestamps } = wellApi;

  const [depthTraces, setDepthTraces] = useState([]);
  const [lossTraces, setLossTraces] = useState([]);

  const [xLim, setXLim] = useState();
  const [yLim, setYLim] = useState();
  const [dateLim, setDateLim] = useState();
  const [depthLim, setDepthLim] = useState();
  const [lossLim, setLossLim] = useState();

  const [tcs, setTcs] = useState();
  const [timestamps, setTimestamps] = useState();
  
  const { RangePicker } = DatePicker;


  useEffect(() => {
    if (!xLim && latest) {
      setXLim([0, latest.Span + latest.Start])
    }

    if (!yLim && latest) {
      setYLim([0, 300])
    }
  }, [latest, xLim, yLim])

  useEffect(() => {
    /*
    Still using the DTSRecord from api, all other traces that are requested are of the simpler form
    based on dtype style, so try to convert to something that works 

    The dead give-away is the captitalized items in the json object
    */

    /*
    Populate the traces with appropriate data
    */
    if (latest) {
    setDepthTraces((existing) => {
      if (!existing.map((e) => e.timestamp).includes(latest.Timestamp)) {
        return [
          convertLatest(
            { ...latest, wellName: well }, // Pass well details
            color // Pass color to convertLatest
          ),
        ];
      }
      return existing;
    });
  }
}, [latest])


  useEffect(() => {
    /* 
    Populate TCs, stripping out unused ones
    */

    const tcMap = wellApi.fetchInfo.data?.tcs;

    if (tcMap && latest) {
      let arr = [];

      for (let i in tcMap) {
        const tc = tcMap[i];

        if (tc.displayed) {
          arr.push(latest.TC[i])
        }
      }

      setTcs(existing => {
        if (existing?.key !== latest.Timestamp) {
          return { 
            key: latest.Timestamp,
            data: arr
          }
        }

        return existing
      })

    } 

    if (latest) {
      //create the loss trace arrays here
      const { Loss, Start, Span, Timestamp } = latest;
      const xData = Array.from({ length: Loss.length }, (_, i) => Start + i);
      const yData = Loss;
      const trace = {
          key: Timestamp,
          colour: 'red',
          pin: false,
          visible: true,
          xData: xData,
          yData: yData,
      };

      setDepthLim([Math.min(...xData), Math.max(...xData)]);
      setLossLim([Math.min(...yData), Math.max(...yData)]);
      setLossTraces([trace]);
      
  }
  }, [ latest, wellApi.fetchInfo.data ])

  // useEffect(() => {
  //   if (wellApi.fetchZone.data) {
  //     const data = wellApi.fetchZone.data;

  //     setZoneTraces(
  //       wellApi.fetchInfo.data?.zones.reduce((acc, zone, i) => {
  //         if (zone.displayed) {
  //           acc.push({
  //             key: zone.name,
  //             xData: data[0],
  //             yData: data[1][i].map(p => p.mx),
  //             colour: '#a87532',
  //           });
  //         }
  //         return acc;
  //       }, [])
  //     );

  //   }
  // }, [wellApi.fetchZone.data, wellApi.fetchInfo.data]);


  const onPanelChange = (key) => {
    // You can use this function to trigger a resize or force update on the charts
    // console.log('Panel changed:', key);
  };


  return (
    <WellContext.Provider value={wellApi}>
      <Collapse defaultActiveKey="latest" onChange={onPanelChange}>
        <Panel header={`${well} Latest`} key="latest">
          <div className="latestChart" style={{ height: '400px', width: '100%' }}>
          <DepthTempChart
              margins={margins}
              traces={depthTraces}
              xLim={xLim}
              yLim={yLim}
              tcs={tcs}
              title={`${well} - ${adjustToBrowserTime(latest.Timestamp)}`}
            />
          </div>
        </Panel>
        {/* <Button 
          type="primary" 
          onClick={onFetchTimestamp}
        >
          Fetch Timestamp
        </Button> */}
        <Panel header={`${well} Fiber Health`} key="zone">
          <div className="zoneChart" style={{ height: '400px', width: '100%', transform: 'translateX(-50px)' }}>
          <DepthLossChart
            margins={marginsLoss}
            traces={lossTraces}
            xLim={depthLim}
            yLim={lossLim}
            title={latest.Timestamp ? `${well} - ${adjustToBrowserTime(latest.Timestamp)}` : 'No Data'}
          />
          </div>
        </Panel>
        {/* <Panel header={`${well} Multi-Well View`} key="multiwell">
          <div className="latestChart" style={{ height: '400px', width: '100%' }}>
            <DepthTempChart
              margins={margins}
              traces={multiWellDepthTraces}
              xLim={xLim}
              yLim={yLim}
              tcs={tcs}
              useCrosshairs={false}
              title={`${well} - ${adjustToBrowserTime(latest.Timestamp)} Multi-Well View`}
            />
          </div>
        </Panel> */}
      </Collapse>
    </WellContext.Provider>
  );
};


const SiteDetailed = (props) => {
  let params = useParams();

  const siteAPI = SiteAPI(params.sitename);
  const { getSiteInfo } = siteAPI;
  const client = useApiClient();

  const user = useContext(UserContext);
  const [visibility, setVisibility] = useState({});

  const [closestDataPoint, setclosestDataPoint] = useState(null);
  const [datePickerTrace, setDatePickerTrace] = useState(dayjs());
  const [timestamps, setTimestamps] = useState({}); // Store fetched timestamps for each well
  const [closestTimestamps, setClosestTimestamps] = useState({}); // Closest timestamps for each well
  const [fetchedTraces, setFetchedTraces] = useState([]); // Store fetched traces for each well at a specific timestamp
  const [latestTracesVisible, setLatestTracesVisible] = useState(true); // Flag for which array to use

  const { RangePicker } = DatePicker;

  const [xLim, setXLim] = useState([]);
  const [tempLim, setTempLim] = useState([0, 300]);
  const hasSetInitialXLim = useRef(false);

  useEffect(() => {
    getSiteInfo.sendRequest();
    const i = setInterval(getSiteInfo.sendRequest, 60 * 1000);
    return () => {
      clearInterval(i);
      getSiteInfo.cancel();
    };
  }, [params.sitename]);

  

  // Sort wells into active and inactive
  const now = useMemo(() => new Date(), []);
  const oneDayAgo = useMemo(() => new Date(now.getTime() - 24 * 60 * 60 * 1000), [now]);

  const sortedWells = useMemo(() => {
    if (!getSiteInfo.data?.wells) return [];

    const activeWells = [];
    const inactiveWells = [];
    const assignedColors = new Map();

    getSiteInfo.data.wells.forEach((well, index) => {
      const color = defaultColors[index % defaultColors.length];
      assignedColors.set(well.name, color); // Map well name to color

      const timestamp = new Date(well.latest.Timestamp);
      const wellWithColor = { ...well, color }; // Add color to each well

      if (timestamp >= new Date(Date.now() - 24 * 60 * 60 * 1000)) {
        activeWells.push(wellWithColor);
      } else {
        inactiveWells.push(wellWithColor);
      }
    });

    return [...activeWells, ...inactiveWells];
  }, [getSiteInfo.data]);

  const allWellsLatest = useMemo(
    () =>
      sortedWells.map((well) => ({
        ...convertLatest(well.latest, well.color), // Pass color to convertLatest
        wellName: well.name,
      })),
    [sortedWells]
  );

  // Initialize visibility state
  useEffect(() => {
    const initialVisibility = sortedWells.reduce((acc, well) => {
      acc[well.name] = true;
      return acc;
    }, {});
    setVisibility(initialVisibility);
  }, [sortedWells]);

  // Filter traces based on visibility
  const visibleTraces = allWellsLatest.filter((trace) => visibility[trace.wellName]);

  // Toggle well visibility
  const toggleVisibility = (wellName) => {
    setVisibility((prev) => ({
      ...prev,
      [wellName]: !prev[wellName],
    }));
  }; 

  useEffect(() => {
    if (!hasSetInitialXLim.current && allWellsLatest.length > 0) {
      const maxDepth = Math.max(...allWellsLatest.flatMap((trace) => trace.xData || []));
      setXLim([0, maxDepth]);
      hasSetInitialXLim.current = true;
    }
  }, [allWellsLatest]);

  // Reset limits
  const resetLimits = () => {
    const maxDepth = Math.max(...allWellsLatest.flatMap((trace) => trace.xData || []));
    setXLim([0, maxDepth]);
    setTempLim([0, 300]);
  };

  // converting the response from the API to a format that can be used in the chart
  const convertToTraceFormat = (traceData, wellName, color) => {
    if (!traceData || traceData.length === 0) {
      console.warn(`No trace data available for well: ${wellName}`);
      return null;
    }

    const trace = traceData[0];
    const { timestamp, start, step, data } = trace;

    if (!timestamp || !start || !step || !data) {
      console.error(`Invalid trace data format for well: ${wellName}`, trace);
      return null;
    }

    const xData = Array.from({ length: data.length }, (_, i) => start + i * step);

    return {
      key: timestamp,
      wellName,
      colour: color, // Use color assigned in sortedWells
      pin: false,
      visible: true,
      xData,
      yData: data,
    };
  };


const fetchTimestampsForAllWells = async (start, end) => {
  const fetchedTimestamps = {};

  for (const well of sortedWells) {
      try {
          // console.log(`Fetching timestamps for well: ${well.name}`);
          const response = await client.get(
              `/dts/site/${params.sitename}/${well.name}/query/timestamps`,
              {
                  params: { start, end },
              }
          );
          // console.log(`Timestamps for well ${well.name}:`, response.data);
          fetchedTimestamps[well.name] = response.data;
      } catch (error) {
          console.error(`Error fetching timestamps for well ${well.name}:`, error);
      }
  }

  setTimestamps(fetchedTimestamps);
  return fetchedTimestamps;
};

const findClosestTimestamps = (fetchedTimestamps, selectedDate) => {
  const closestTimestampsMap = {};

  Object.keys(fetchedTimestamps).forEach((wellName) => {
      const wellTimestamps = fetchedTimestamps[wellName];
      if (wellTimestamps && wellTimestamps.length > 0) {
          let closest = wellTimestamps[0];
          let minDiff = Math.abs(new Date(closest) - new Date(selectedDate));

          wellTimestamps.forEach((timestamp) => {
              const diff = Math.abs(new Date(timestamp) - new Date(selectedDate));
              if (diff < minDiff) {
                  closest = timestamp;
                  minDiff = diff;
              }
          });

          closestTimestampsMap[wellName] = closest;
      }
  });

  setClosestTimestamps(closestTimestampsMap);
  return closestTimestampsMap;
};

const fetchTracesForTimestamps = async (closestTimestamps) => {
  const traces = [];
  for (const [wellName, timestamp] of Object.entries(closestTimestamps)) {
    const well = sortedWells.find((w) => w.name === wellName);
    const color = well?.color || "#ccc";

    try {
      const response = await client.get(
        `/dts/site/${params.sitename}/${wellName}/timestamp`,
        { params: { timestamp, dtype: "temp" } }
      );
      const convertedTrace = convertToTraceFormat(response.data, wellName, color);
      if (convertedTrace) traces.push(convertedTrace);
    } catch (error) {
      console.error(`Error fetching trace for well ${wellName}:`, error);
    }
  }
  setFetchedTraces(traces);
  return traces;
};


const datePicker = useCallback(() => {
  const onOk = async (date) => {
      // console.log("Selected date from date picker:", date.toISOString());
      setDatePickerTrace(date);

      const start = date.startOf("day").toISOString();
      const end = date.endOf("day").toISOString();

      // console.log(`Fetching timestamps for wells in range: ${start} - ${end}`);

      try {
          // Step 1: Fetch timestamps for all wells
          const fetchedTimestamps = await fetchTimestampsForAllWells(start, end);
          // console.log("Fetched timestamps for all wells:", fetchedTimestamps);

          // Step 2: Find closest timestamps
          const closestTimestampsMap = findClosestTimestamps(fetchedTimestamps, date.toISOString());
          // console.log("Closest timestamps for each well:", closestTimestampsMap);

          // Step 3: Fetch traces for the closest timestamps
          await fetchTracesForTimestamps(closestTimestampsMap);
      } catch (error) {
          console.error("Error in the date picker flow:", error);
      }
    setLatestTracesVisible(false);
  };

  return (
      <DatePicker
          showTime
          onOk={onOk}
          value={datePickerTrace}
          format={"YYYY-MM-DDTHH:mm:ssZ"}
          allowClear={false}
      />
  );
}, [datePickerTrace, fetchTimestampsForAllWells, findClosestTimestamps, fetchTracesForTimestamps]);

useEffect(() => {
  
  // Reset date picker to current date
  setDatePickerTrace(dayjs()); // Reset date picker to "now"

  // Reset to latest traces
  setLatestTracesVisible(true); // Ensure the latest traces are displayed

}, [params.sitename]);

const setLatestTracesVisibleClick = ( )  => {
  setLatestTracesVisible(true);
}

  return (
    <>
      <Title level={3}>
        {getSiteInfo.data?.name} {user.admin && <Link to="edit"><EditOutlined /></Link>}
      </Title>
      <PdecStatus state={getSiteInfo.data?.state} />
      <Divider />
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
        {datePicker(closestDataPoint?.date)}
        <Button type="primary" onClick={setLatestTracesVisibleClick} disabled={latestTracesVisible}>
          Latest Traces
        </Button>
      </div>
      <Collapse>
      <Panel header="Multi-Well View" key="multiwell">
      <div
            className="legend"
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "16px",
              marginBottom: "16px",
            }}
          >
            {sortedWells.map((well) => {
              // Find the matching trace for the current well from fetchedTraces or visibleTraces
              const currentTrace = (latestTracesVisible ? visibleTraces : fetchedTraces).find(
                (trace) => trace.wellName === well.name
              );
              if (well.displayed) {
              return (
                <div
                  key={well.name}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    padding: "4px",
                    marginRight: "8px",
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: currentTrace?.colour || "#ccc",
                        borderRadius: "50%",
                        marginRight: "8px",
                        border: "1px solid #000",
                      }}
                    ></div>
                    <Checkbox
                      checked={visibility[well.name]}
                      onChange={() => toggleVisibility(well.name)}
                    >
                      {well.name}
                    </Checkbox>
                  </div>
                  <div
                    style={{
                      marginLeft: "24px", // Aligns the timestamp text
                      fontSize: "12px",
                      color: "#888",
                    }}
                  >
                    Timestamp:{" "}
                    {currentTrace?.key
                      ? dayjs(currentTrace.key).format("YYYY-MM-DD HH:mm:ss")
                      : "N/A"}
                  </div>
                </div>
              );
            }})}
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            <LimitsDropdownOverlay
              depthLim={xLim}
              tempLim={tempLim}
              setDepthLim={setXLim}
              setTempLim={setTempLim}
            />
            <Button type="primary" onClick={resetLimits}>
              Reset Lims
            </Button>
          </div>
          <div className="multiWellChart" style={{ height: "400px", width: "100%" }}>
          <DepthTempChart
              margins={margins}
              traces={(latestTracesVisible ? visibleTraces : fetchedTraces).filter(
                (trace) => visibility[trace.wellName]
              )}
              xLim={[xLim[0], xLim[1] + 2]}
              yLim={tempLim}
              title={`Multi-Well Traces`}
              usePointOverlay={false}
            />
          </div>
        </Panel>
      </Collapse>
      <Divider />
      {sortedWells.map(w => {
        if (w.displayed) {
          return (
            <div key={w.name}>
              <Link to={`/site/${params.sitename}/${w.name}`}>{w.name}</Link>
              <WellSummary 
                site={getSiteInfo.data?.name} 
                well={w.name} 
                latest={w?.latest}
                color={w.color}
              />
              <Divider />
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default SiteDetailed;
