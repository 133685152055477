import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, Divider } from 'antd';
import { DownOutlined } from '@ant-design/icons';

export const LimitsDropdownOverlay = ({
  depthLim,
  tempLim,
  setDepthLim,
  setTempLim,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [depthInput, setDepthInput] = useState({ min: '', max: '' });
  const [tempInput, setTempInput] = useState({ min: '', max: '' });
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  const toggleDropdown = () => setIsDropdownVisible((prev) => !prev);

  const handleSetDepthLim = () => {
    const min = parseInt(depthInput.min, 10);
    const max = parseInt(depthInput.max, 10);

    setDepthLim([
      !isNaN(min) ? min : depthLim[0], // Use new min if provided, otherwise keep current min
      !isNaN(max) ? max : depthLim[1], // Use new max if provided, otherwise keep current max
    ]);

    // Clear the inputs and close the dropdown
    setDepthInput({ min: '', max: '' });
    setIsDropdownVisible(false);
  };

  const handleSetTempLim = () => {
    const min = parseFloat(tempInput.min);
    const max = parseFloat(tempInput.max);

    setTempLim([
      !isNaN(min) ? min : tempLim[0], // Use new min if provided, otherwise keep current min
      !isNaN(max) ? max : tempLim[1], // Use new max if provided, otherwise keep current max
    ]);

    // Clear the inputs and close the dropdown
    setTempInput({ min: '', max: '' });
    setIsDropdownVisible(false);
  };

  const restrictToNumbers = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {/* Main Dropdown Trigger */}
      <div
        ref={triggerRef} // Attach ref to the trigger
        onClick={toggleDropdown}
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          padding: '5px',
          backgroundColor: '#f5f5f5',
          borderRadius: '5px',
          border: '1px solid #ddd',
        }}
      >
        Axis Limits
        <DownOutlined
          style={{
            fontSize: '12px',
            transform: isDropdownVisible ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </div>

      {/* Dropdown Overlay */}
      {isDropdownVisible && (
        <div
          ref={dropdownRef} // Attach ref to the dropdown
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            backgroundColor: 'white',
            border: '1px solid #ddd',
            padding: '10px',
            zIndex: 1000,
            borderRadius: '5px',
            width: '175px',
          }}
        >
          {/* Depth Limits Section */}
          <div style={{ marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Depth Limits</span>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Input
              style={{ width: '100%', fontSize: '12px' }}
              value={depthInput.min}
              placeholder={`Min: ${depthLim[0]}`}
              onFocus={() =>
                setDepthInput((prev) => ({ ...prev, min: prev.min || depthLim[0].toString() }))
              }
              onChange={(e) =>
                setDepthInput((prev) => ({ ...prev, min: e.target.value }))
              }
              onKeyPress={restrictToNumbers}
            />
            <Input
              style={{ width: '100%', fontSize: '12px' }}
              value={depthInput.max}
              placeholder={`Max: ${depthLim[1]}`}
              onFocus={() =>
                setDepthInput((prev) => ({ ...prev, max: prev.max || depthLim[1].toString() }))
              }
              onChange={(e) =>
                setDepthInput((prev) => ({ ...prev, max: e.target.value }))
              }
              onKeyPress={restrictToNumbers}
            />

              <Button type="primary" size="small" onClick={handleSetDepthLim}>
                Set Depth
              </Button>
            </div>
          </div>

          <Divider />
          {/* Temp Limits Section */}
          <div>
            <span style={{ fontWeight: 'bold' }}>Temp Limits</span>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Input
              style={{ width: '100%', fontSize: '12px' }}
              value={tempInput.min}
              placeholder={`Min: ${tempLim[0]}`}
              onFocus={() =>
                setTempInput((prev) => ({ ...prev, min: prev.min || tempLim[0] }))
              }
              onChange={(e) =>
                setTempInput((prev) => ({ ...prev, min: e.target.value }))
              }
              onKeyPress={restrictToNumbers}
            />
            <Input
              style={{ width: '100%', fontSize: '12px' }}
              value={tempInput.max}
              placeholder={`Max: ${tempLim[1]}`}
              onFocus={() =>
                setTempInput((prev) => ({ ...prev, max: prev.max || tempLim[1] }))
              }
              onChange={(e) =>
                setTempInput((prev) => ({ ...prev, max: e.target.value }))
              }
              onKeyPress={restrictToNumbers}
            />

              <Button type="primary" size="small" onClick={handleSetTempLim}>
                Set Temp
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
